const Images = {
    
     KOL: require('../assets/img/Kolkata.png'),
     HYD: require('../assets/img/Hyderabad.png'),
     NODATA: require('../assets/img/nodata.png'),
     SMONEY: require('../assets/img/SAMPLE MONEY.png'),
     FOOTBALWP: require('../assets/img/Footballwatchparty.png'),
     COFFEESM: require('../assets/img/COFFEE_SHOP_MEETING.png'),
     BELL: require('../assets/img/bell.png'),
     MSG: require('../assets/img/msg.png'),
     LIST: require('../assets/img/list.png'),
     TWITTER: require('../assets/img/twitter.png'),
     INSTA: require('../assets/img/insta.png'),
     FACEBOOK: require('../assets/img/facebook.png'),
     EYE: require('../assets/img/preview.png'),
     SHAPE: require('../assets/img/Shape.png'),
     DROPDOWN: require('../assets/img/dropdown.png'),
     DROPDOWNDARK: require('../assets/img/dropdarkdown.png'),
     PIN: require('../assets/img/pin.png'),
     Bicon: require('../assets/img/Bicon.png'),
     Micon: require('../assets/img/Micon.png'),
     Picon: require('../assets/img/Picon.png'),
     Sicon: require('../assets/img/Staricon.png'),
     Pinpink: require('../assets/img/pinpink.png'),
     INFO: require('../assets/img/info.png'),
     CENCLEBTN: require('../assets/img/cancel-icon.svg'),
     ADDBTN: require('../assets/img/add-icon.svg'),
     COINIMG: require('../assets/img/coin-ic.png'),
     PROMOTE: require('../assets/img/promote.png'),
     PIN_ACTIVE: require('../assets/img/pin-active.png'),
     SALARY_REVIEW_BAR: require('../assets/img/salary-review-bar.png'),
     CHOOSE_TEMPLATE_BAR: require('../assets/img/choose-template.png'),
     
     SEND_ICON: require('../assets/img/send-icon.png'),
     dummy_user: require('../assets/img/avatar.jpg'),   
     no_image: require('../assets/img/no_image.png'),
     FACEBOOK_CIRCLE: require('../assets/img/facebook_circle.png'),
     INSTA_CIRCLE: require('../assets/img/insta_circle.png'),
     WATSAPP_CIRCLE: require('../assets/img/watsapp_circle.png'),
     VERIFIED: require('../assets/img/verified.png'),
     DEFAULT_CIRCLE: require('../assets/img/default_circle.png'),
     FLAG_ENABLE: require('../assets/img/flag_enable.png'),
     NO_IMAGE: require('../assets/img/no_image.png'),

     //Perfectlineup
     DEFAULT_THUMB: require('../assets/img/image-gallery.png'),
     CANCEL_BTN: require('../assets/img/cancel.png'),
     PLUS_BTN: require('../assets/img/plus.png'),
     edit_iv: require('../assets/img/edit_iv.png'),
     delete_iv: require('../assets/img/delete_iv.png'),
     apple: require('../assets/img/apple.png'),
     android: require('../assets/img/android.png'),
     copy_files: require('../assets/img/copy-files.png'),
     
     
}
export default Images;