import * as NC from "../src/helper/NetworkingConstants";
const items= {
  items: [
    { 
      name: 'Dashboard',
      url: '/dashboard',
    },
    { 
      name: 'ManageUsers',
      url: '/manage_users',
    },
    { 
      name: 'Default Lineupout',
      url: '/lineupout',
    },
    { 
      name: 'ExpertAdvice',
      url: '/expert_advice',
    },
    { 
      name: 'Announcement',
      url: '/announcement',
    },
    { 
      name: 'Notification',
      url: '/notification',
    },
    
  ]
};
export default items;
