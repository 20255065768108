import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';
import UserDetails from './views/UserDetails/UserDetails';

function Loading() {
  return <div>Loading...</div>;
}

const DashboardView = Loadable({
   loader: () => import('./views/DashboardView/DashboardView'),
  //loader: () => import('./views/DashboardView/NewDashboard'),
  loading: Loading,
});
const ExpertAdvice = Loadable({
  loader: () => import('./views/ExpertAdvice/ExpertAdvice'),
  loading: Loading,
});

const Announcement = Loadable({
  loader: () => import('./views/Announcement/Announcement'),
  loading: Loading,
});
const ManageUser = Loadable({
  loader: () => import('./views/ManageUser/ManageUser'),
  loading: Loading,
});
const Lineupout = Loadable({
  loader: () => import('./views/Lineupout/Lineupout'),
  loading: Loading,
});
const Notification = Loadable({
  loader: () => import('./views/Notification/Notification'),
  loading: Loading,
});

const CreateNotification = Loadable({
  loader: () => import('./views/Notification/CreateNotification'),
  loading: Loading,
});
const AddImage = Loadable({
  loader: () => import('./views/Notification/ManageImage'),
  loading: Loading,
});


const Logout = Loadable({
  loader: () => import('./views/Pages/Login/Logout'),
  loading: Loading,
});

const routes = [
  { path: '/expert_advice', name: 'ExpertAdvice', component: ExpertAdvice },
  { path: '/announcement', name: 'Announcement', component: Announcement },
  { path: '/manage_users', name: 'ManageUser', component: ManageUser },
  { path: '/dashboard', name: 'DashboardView', component: DashboardView },
  { path: '/user_info/:user_unique_id', name: 'UserDetails', component: UserDetails },
  { path: '/lineupout', name: 'Lineupout', component: Lineupout },
  { path: '/notification', name: 'Notification', component: Notification },
  { path: '/create_notification', name: 'Notification', component: CreateNotification },
  { path: '/add_image', name: 'AddImage', component: AddImage },
  { path: '/logout', name: 'logout', component: Logout },
  
  // { path: '/', name: 'Home', component: DefaultLayout, exact: true },
  // { path: '/verify-fantasy-domain/:encoded_auth_key', name: 'Verify Domain', component: VerifyFantasyDomain },
  // { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  // { path: '/contest/createtemplatecontest/:league_id/:season_game_uid', name: 'CreateTemplateContest', component: CreateTemplateContest, exact: true},
  // { path: '/contest/fixturecontest/:league_id/:season_game_uid', name: 'FixtureContest', component: FixtureContest, exact: true},
  // { path: '/contest/createcontest/:league_id/:season_game_uid', name: 'CreateContest', component: CreateContest, exact: true},
];

export default routes;
