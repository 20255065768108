import React from "react";
import SpinnerLoader from "react-loader-spinner";

export default class Loader extends React.Component {
  //other logic
  /** can check more loader option from below url and change *type* variable
   *    https://mhnpd.github.io/react-loader-spinner/?selectedKind=Loader&selectedStory=Bars&full=0&addons=0&stories=1&panelRight=0
   * */

  render() {
    return (
      <div
        className={
          "loader-back " +
          (window.location.pathname === "/" ? "max-width-100" : "")
        }
      >
        <SpinnerLoader color={"#FFFFFF"} height="50" width="100" />
        {this.props.loadingMessage !== "" && (
          <div className="msg">{this.props.loadingMessage}</div>
        )}
      </div>
    );
  }
}
