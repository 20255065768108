import React, { Component } from 'react';
import { DropdownMenu, DropdownToggle, Nav,Row} from 'reactstrap';
import PropTypes from 'prop-types';

import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';

import pl_logo from '../../assets/img/brand/pl_logo.png'
// import logo from '../../assets/img/brand/logo.png'
import sygnet from '../../assets/img/brand/sygnet.svg'
import avatar from '../../assets/img/avatars/default_user.png'
import list from '../../assets/img/avatars/list.png'
import msg from '../../assets/img/avatars/msg.png'
import bell from '../../assets/img/avatars/bell.png'
import MasterSportSelection from '../../views/Common/MasterSportSelection';
import Col from 'reactstrap/lib/Col';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {

  onMasterSportsChange = (selected_sport) => {
    //console.log(selected_sport);
  }

  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
       
        <AppNavbarBrand
          full={{ src: pl_logo, width: 50,  alt: 'CoreUI Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />        
             {/* <div className="sports-selector-container">
                 <MasterSportSelection  masterSportsChange = {(e)=>this.onMasterSportsChange(e)} testdata={'helloo'} {...this.props} />
            </div> */}
        <Nav className="ml-auto top-navigation" navbar>
          {/* <AppHeaderDropdown >
            <DropdownToggle nav>
          <img src = {bell} className="nav-icon" alt=""/>
            </DropdownToggle>
            </AppHeaderDropdown>
          <AppHeaderDropdown >
          <DropdownToggle nav >
              <img src={msg} className="nav-icon" alt=""/>
            </DropdownToggle> 
            </AppHeaderDropdown>
            <AppHeaderDropdown >
              <DropdownToggle nav >
              <img src={list} className="nav-icon" alt=""/>
            </DropdownToggle>  
            </AppHeaderDropdown> */}
          {/* <AppHeaderDropdown>           
            <DropdownToggle nav>            
            <img src={avatar} className="img-avatar" alt="Admin" />
            </DropdownToggle> */}
            {/* <DropdownMenu style={{ height: '20px' ,    left: '-28px'}}>
            <a  className="btn btn-default" href="#/logout">Log Out</a>
            </DropdownMenu> */}
          {/* </AppHeaderDropdown> */}
          <a  className="btn btn-default" style={{ color: '#fff' }} href="#/logout">Log Out</a>
        </Nav>
        {/* <AppAsideToggler className="d-md-down-none" />
        <AppAsideToggler className="d-lg-none" mobile /> */}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
