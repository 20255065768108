import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import _ from "lodash";
import * as NC from "../../helper/NetworkingConstants";
import WSManager from "../../helper/WSManager";

import LS from 'local-storage';
//import { Redirect, Route, Switch } from 'react-router-dom';

// React select
import Select from 'react-select';
import 'react-select/dist/react-select.min.css';

import { createBrowserHistory } from 'history'
import { withRouter } from 'react-router'
const history = createBrowserHistory();

class MasterSportSelection extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      collapse: true,
      fadeIn: true,
      timeout: 300,
      selected_sport: 0,
      sports_list: [],
      sportsFiterFlag : true
    };
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  getMasterSports = () => {
    WSManager.Rest(NC.baseURL + NC.GET_ALL_SPORTS, {}).then((responseJson) => {
      if (responseJson.response_code === NC.successCode) {

        this.setState({ posting: false });
        var sports_list = [];
        _.map(responseJson.data, function (item) {
          sports_list.push({
            value: item.sports_id,
            label: item.sports_name
          });
        });
        this.setState({ sports_list: sports_list });

        LS.set('sports_list', sports_list);

      }
      else if (responseJson.response_code == NC.sessionExpireCode) {
        this.props.history.push('/401');
      } else {
        this.setState({ posting: false });
      }
    })
  }

  changeSelectedSport = (e) => {
    if (e) {
      this.setState({ selected_sport: e.value }, function () { console.log('selected_sport: ', this.state.selected_sport) })
      this.props.masterSportsChange(e.value);

      LS.set('selected_sport', e.value);
      /* if(history.location.hash == '#/game_center/DFS'){
        window.location.reload();
      }else{
        window.location.href = '#/game_center/DFS';
      } */
      window.location.reload();
      //history.go()
    }
  }

  componentDidMount() {
    this.getMasterSports();

    var selected_sport = LS.get('selected_sport');
    if (!selected_sport) {
      selected_sport = NC.sportsId;
    }
    this.setState({ selected_sport: selected_sport })
    LS.set('selected_sport', selected_sport);
    console.log('LS selected_sport: ', selected_sport);

  }

  componentWillReceiveProps(nextProps) {
    let pathname = nextProps.location.pathname
    if (pathname == '/game_center/DFS' || pathname == '/game_center/Teams' || pathname == '/contest/contestlist' || pathname == '/contesttemplate')
    {this.setState({sportsFiterFlag : true})}
    else{this.setState({sportsFiterFlag : false})}
  }

  render() {
    return (
      <div className="animated fadeIn">
        {
          this.state.sportsFiterFlag &&
          <Row>
            <Col xl="6" sm="8">
              <Select
                clearable={false}
                className="sports-seletor"
                name="selected_sport"
                placeholder="Select Sport"
                menuIsOpen={true}
                value={this.state.selected_sport}
                options={this.state.sports_list}
                onChange={(value) => this.changeSelectedSport(value)}
              />
            </Col>
          </Row>
        }        
      </div>
    );
  }
}
export default withRouter(MasterSportSelection)